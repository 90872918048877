import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollRestoration from "react-scroll-restoration";
import { LinearProgress } from "@material-ui/core";
import { Toaster } from "react-hot-toast";
import "./App.css";

const Announce = React.lazy(() => import("./components/Announce/Announce"));
const Navbar = React.lazy(() => import("./components/Navbar/Navbar"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const About = React.lazy(() => import("./pages/About/About"));
const Projects = React.lazy(() => import("./pages/Projects/Projects"));
const Blog = React.lazy(() => import("./pages/Blog/Blog"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const FourOFour = React.lazy(() => import("./pages/FourOFour/FourOFour"));
const Values = React.lazy(() => import("./pages/Values/Values"));
const Privacy = React.lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const Terms = React.lazy(() => import("./pages/TOS/TOS"));
const Community = React.lazy(() => import("./pages/Community/Community"));
const CoC = React.lazy(() => import("./pages/CoC/CoC"));

const queryClient = new QueryClient();

function App() {
  return (
    <Suspense fallback={<LinearProgress color="primary" />}>
      <Toaster
        position="bottom-right"
        toastOptions={{
          className: "font-style-cta",
          style: {
            backgroundColor: "#383838",
            color: "#FFFFFF",
            border: "0.2rem solid #D6AD60",
            cursor: "default",
            userSelect: "none",
          },
        }}
      />
      <Announce />
      <Navbar />
      <QueryClientProvider client={queryClient}>
        <ScrollRestoration />
        <Switch>
          <Route exact path="/">
            <About />
          </Route>
          <Route path="/thecode">
            <CoC />
          </Route>
          <Route path="/community">
            <Community />
          </Route>
          <Route path="/portfolio">
            <Projects />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/connect">
            <Contact />
          </Route>
          <Route path="/values">
            <Values />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/">
            <FourOFour />
          </Route>
        </Switch>
      </QueryClientProvider>
      <Footer />
    </Suspense>
  );
}

export default App;
